<script setup>
  const { event } = defineProps(['event']);

  const startsAt = event.prelims_starts_at || event.starts_at;
  const hasStarted = useDate().hasStarted(startsAt);
</script>

<!----------------------------------------------------------------------------->

<template>
  <HomeHeroCard :event="event">
    <div class="heading">
      <EventLogo small :event="event" />
      <EventDateLocation v-if="!hasStarted" :event="event" class="date-top" />
      <h3>{{ event.fight_title }}</h3>
    </div>
    <h2>
      <FightTitleCombo>
        <template v-slot:whiteCorner>{{ event.white_corner?.last_name ?? 'TBD' }}</template>
        <template v-slot:blackCorner>{{ event.black_corner?.last_name ?? 'TBD' }}</template>
      </FightTitleCombo>
    </h2>
    <div class="footing">
      <h4><IconPlus />{{ useTournamentSize(event.size) }}{{ event.tournament_title }}</h4>
      <TickerTape v-if="event.tournament_fighter_names" :items="event.tournament_fighter_names" class="ticker" />
    </div>
    <EventDateLocation v-if="!hasStarted" :event="event" class="date-bottom" />
  </HomeHeroCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .heading {
    margin: px(92) 0 px(24);

    display: flex;
    align-items: center;

    gap: px(24);

    .date-top {
      display: none;
    }

    h3 {
      @include title-s;
      margin-bottom: -0.17em;
    }
  }

  h2 {
  }

  .footing {
    display: flex;
    align-items: center;
    gap: px(13);

    margin: px(32) 0 px(22);

    h4 {
      display: flex;
      align-items: center;

      flex-shrink: 0;

      @include title-s;
      font-size: px(44);
      margin-bottom: -0.25em;

      gap: px(12);

      svg {
        margin-top: px(-6);
      }
    }

    .ticker {
      width: 100%;
    }
  }

  .date-bottom {
    margin-bottom: px(-12);
  }

  @include break-medium {
    .footing {
      h4 {
        gap: px(9);
        svg {
          width: px(16);
          margin-top: px(-5);
        }
      }
    }
  }

  @include break-small {
    .heading {
      flex-direction: column;
      gap: px(4);

      margin-top: 0; //px(109);
      margin-bottom: px(20);

      h3 {
        margin-top: px(16);
        text-align: center;
      }

      .date-top {
        display: block;
      }
    }

    h2 {
      align-self: stretch;
    }

    .footing {
      flex-direction: column;
      margin: px(8) 0 0;

      h4 {
        font-size: px(32);
        gap: px(8);

        svg {
          width: px(10);
          margin-top: px(-4);
        }
      }

      .ticker {
        min-width: 100vw;
      }
    }

    .date-bottom {
      display: none;
    }
  }

  @include break-phone {
    .heading {
      // margin-top: 22vw;
    }

    .footing {
      h4 {
        font-size: 8vw;
      }
    }
  }
</style>
