<script setup>
  import { nextTick, onMounted, ref } from 'vue';

  const { sponsors } = defineProps(['sponsors']);

  const visibleSponsors = ref([]);
  const availableSponsors = ref([]);
  const fadeOutIndex = ref(null);
  const fadeInIndex = ref(null);

  // --

  const slotOrder = ref([]);
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const generateSlotOrder = () => {
    slotOrder.value = shuffleArray([0, 1, 2, 3]);
  };

  // --

  let cycleIndex = 0;

  const cycleSponsors = () => {
    if (availableSponsors.value.length === 0) return;

    const newSponsor = availableSponsors.value.shift();
    const targetSlot = slotOrder.value[cycleIndex];

    fadeOutIndex.value = targetSlot;

    setTimeout(async () => {
      const oldSponsor = visibleSponsors.value[targetSlot];

      visibleSponsors.value[targetSlot] = newSponsor;
      fadeOutIndex.value = null;
      fadeInIndex.value = targetSlot;

      if (oldSponsor) {
        availableSponsors.value.push(oldSponsor);
      }

      await nextTick();

      setTimeout(() => {
        fadeInIndex.value = null;
        setTimeout(cycleSponsors, 2000);
      }, 1000);
    }, 500);

    cycleIndex = (cycleIndex + 1) % 4;
  };

  // --

  onMounted(() => {
    if (sponsors.length <= 4) {
      visibleSponsors.value = [...sponsors];
      return;
    }

    const shuffled = shuffleArray([...sponsors]);
    visibleSponsors.value = shuffled.slice(0, 4);
    availableSponsors.value = shuffled.slice(4);

    generateSlotOrder();
    setTimeout(cycleSponsors, 2000);
  });
</script>

<template>
  <div class="sponsors">
    <template v-for="(sponsor, index) in visibleSponsors" :key="sponsor.id || index">
      <div class="sponsor-wrapper" :class="{ fadingOut: index === fadeOutIndex, fadingIn: index === fadeInIndex }">
        <NuxtLink v-if="sponsor.link" :to="sponsor.link" class="sponsor" target="_blank">
          <img :src="sponsor.logo.url" :alt="sponsor.logo?.alt" />
        </NuxtLink>
        <img v-else :src="sponsor.logo.url" :alt="sponsor.logo?.alt" class="sponsor" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .sponsors {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 px(36);
    gap: 10px;

    @include break-small {
      justify-content: center;
    }
  }

  .sponsor-wrapper {
    position: relative;
    width: 25%;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    will-change: opacity;
  }

  .sponsor-wrapper.fadingOut {
    opacity: 0;
  }

  .sponsor-wrapper.fadingIn {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
