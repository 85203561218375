<script setup>
  const { item } = defineProps(['item', 'hide_title', 'solid', 'darken']);
  const { title } = item;
  const url = ref(item.url);
  const type = item.blueprint.handle;

  if (type === 'youtube') {
    url.value = item.youtube_link;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation fadeOnly>
    <ClickableCard :to="url" :class="[(solid ? 'solid' : ''), ((darken && !hide_title) ? 'darken' : ''), type]">
      <aside>
        <GridItem :from="2" :to="6" class="info">
          <NewsMeta :item="item" white />
          <div>
            <h2 v-if="!hide_title">{{ title }}</h2>
            <ArrowButton noAnchor white class="button" />
          </div>
        </GridItem>
      </aside>

      <aside class="solid-wrapper">
        <GridItem :from="2" :to="6" class="info">
          <NewsMeta :item="item" />
          <div>
            <h2>{{ title }}</h2>
            <ArrowButton noAnchor white class="button" />
          </div>
        </GridItem>
      </aside>
      <!-- <div class="picture-logo-wrapper"> -->
      <div class="picture-container"><Picture preset="landscape-1920" :image="useThumbnail(item, 'large')" /></div>
      <div class="youtube-logo"><img :src="useCdn().generateStaticAsset('images/youtube.png')" alt="youtube" /></div>
      <!-- </div> -->
    </ClickableCard>
  </ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .card {
    position: relative;

    &.darken {
      @include break-min-small {
        @include dark-layer;
      }
    }

    @include hover {
      .picture-container {
        picture {
          transform: scale(103%);
          transition: transform 0.48s $quadOut;
        }
      }

      :deep(.arrow-button) {
        background-color: $white;
        border-color: $white;

        path {
          fill: $black;
        }
      }
    }

    .picture-container {
      position: absolute;

      top: 0;

      width: 100%;
      height: 100%;
      user-select: none;

      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      picture {
        transform: scale(100%);
        transition: transform 0.31s $quadInOut;
      }
    }

    .youtube-logo {
      position: absolute;
      display: none;

      bottom: 0;
      right: 0;

      width: px(156);
      margin-bottom: px(19);
      margin-right: px(16);
    }

    &.youtube {
      .youtube-logo {
        display: block;
      }
    }

    aside {
      position: relative;
      z-index: $layer-foreground;

      width: 100%;
      min-height: px(600);

      @include grid;

      .info {
        display: flex;
        flex-direction: column;

        justify-content: space-between;

        height: 100%;
        padding: px(38) 0;

        h2 {
          max-width: px(480);
          color: $white;
        }

        .arrow-button {
          margin-top: px(22);
        }
      }
    }

    .solid-wrapper {
      @include grid;

      // position: absolute;
      top: 0;

      display: none;

      height: auto;
      min-height: 0;

      background-color: $black;
    }

    @include break-small {
      aside {
        min-height: 100vw;

        .info {
          @include column(2, 11);
          justify-content: flex-end;

          gap: px(16);

          h2 {
            font-size: px(64);
          }
        }
      }

      &:not(.solid) {
        &:after {
          position: absolute;
          content: '';

          width: 100%;
          height: 100%;

          top: 0;
          left: 0;

          background: linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 50%);
        }
      }

      &.solid {
        min-height: 100vw;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        .info {
          justify-content: flex-start;
        }

        aside {
          display: none;
        }
        .solid-wrapper {
          display: grid;
        }
      }
    }

    @include break-phone {
      &.solid {
        min-height: 110vw;
      }

      aside {
        min-height: 110vw;

        .info {
          padding: px(24) 0;

          h2 {
            font-size: px(40);
          }
        }
      }
    }
  }
</style>
