<script setup>
  const taxonomyStore = useTaxonomyStore();

  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  const categories = taxonomyStore.getTaxonomy('categories');

  // --

  const highlightIDs = page.highlights
    ?.map((val) => {
      return val.id;
    })
    .join('|');

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <NewsHero :highlights="page.highlights" :hide_title="page.hide_title" :solid="page.solid" :darken="page.darken">{{ page.title }}</NewsHero>
  <NewsArchive :categories="categories" :skip="highlightIDs" />
  <FooterExtension />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
