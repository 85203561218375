<script setup>
  const { items } = defineProps(['items', 'hide_title', 'solid', 'darken']);

  const mainItem = items[0];
  const secondaryItems = items.slice(1, 3);
</script>

<!----------------------------------------------------------------------------->

<template>
  <Wrapper wide class="wrapper">
    <NewsCardMain :item="mainItem" :hide_title="hide_title" :solid="solid" :darken="darken" />
    <div class="secondary" v-if="secondaryItems.length > 0">
      <NewsCardDual v-for="item in secondaryItems" :key="item.id" :item="item" />
    </div>
  </Wrapper>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .wrapper {
    display: flex;
    flex-direction: column;

    gap: px(32);

    @include break-small {
      gap: $margin-small;
    }

    @include break-phone {
      gap: $margin-phone-wide;
    }
  }

  .secondary {
    display: flex;
    justify-content: space-between;
    gap: $grid-gap;

    min-height: px(326);
    border: px(1) solid $grey-3;

    border-left: 0;
    border-right: 0;

    padding: px(32) 0;

    @include break-large {
      flex-direction: column;

      gap: px(32);
    }

    @include break-small {
      border: none;
      padding: 0;

      gap: px(48);
    }
  }
</style>
