<script setup>
  const { event, buttons, useBackground, showSoldOut } = defineProps({
    event: Object,
    buttons: Array,
    useBackground: Boolean,
    showSoldOut: Boolean,
  });

  const colorName = useEventColor(event);
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation>
    <Card
      :class="{
        'sold-out': showSoldOut && event.sold_out,
        'has-ticker': event.ticket_sale_ticker || (showSoldOut && event.sold_out),
      }"
      :color="colorName"
      :style="{ 'background-image': useBackground ? `url(${useCdn().generateStaticAsset('images/watch_card_bg.png')})` : '' }"
    >
      <GridItem :from="2" :to="11" class="container">
        <div class="heading">
          <EventLogo small :event="event" class="logo" />
          <EventDateTimeLocation :event="event" />
        </div>
        <slot />
      </GridItem>
      <div class="button-wrapper">
        <ButtonGroup v-if="buttons.length" class="buttons" :buttons="buttons" :event="event" centerMobile stackMobile />
      </div>
      <div class="tape">
        <TickerTape
          v-if="event.ticket_sale_ticker && !(showSoldOut && event.sold_out)"
          :items="[event.ticket_sale_ticker]"
          class="tape-tickets"
        />
        <TickerTape red v-if="showSoldOut && event.sold_out" :items="['Sold out']" class="tape-sold-out" />
      </div>
    </Card>
  </ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .card {
    @include grid;
    height: px(436);

    overflow: hidden;

    background-size: cover;
    background-position: center center;

    .heading {
      display: flex;
      align-items: flex-end;

      gap: px(27);

      .logo {
        margin-bottom: -0.03em;
      }

      .date-location {
        margin-bottom: -0.45em;
      }

      margin-bottom: px(25);
    }

    .container {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;

      margin-top: px(-48);
    }

    .button-wrapper {
      @include break-min-small {
        position: absolute;

        width: 100%;

        right: px(40);
        bottom: px(38);

        display: flex;
        justify-content: flex-end;

        .buttons {
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }

    &.has-ticker {
      .button-wrapper {
        bottom: px(38 + 30);
      }
    }

    &.sold-out {
      .container {
        opacity: 0.3;
      }
    }

    .tape {
      position: absolute;

      width: 100%;

      left: 0;
      bottom: 0;
    }

    @include break-medium {
      height: px(400);
    }

    @include break-small {
      height: auto;
      padding: px(48) px(28);

      &.has-ticker {
        padding-bottom: px(72);
      }

      .tape {
        @include unwrap;
      }

      .heading {
        flex-direction: column;
        align-items: center;
        gap: px(8);
      }

      .container {
        margin-top: 0;
        margin-bottom: px(44);

        @include column(1, 12);
      }

      &.sold-out {
        .container {
          margin-bottom: 0;
        }
      }

      .button-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }
</style>
