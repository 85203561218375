<script setup>
  const { event } = defineProps(['event']);

  const mainTime = useDate().formatTime(event.starts_at);
  const prelimsTime = useDate().formatTime(event.prelims_starts_at);
  const timezone = useDate().guessTimezone();

  // --

  const channelStore = usePpvChannelsStore();
  await channelStore.fetch();

  const country = ref('-');
  const hasCountry = ref(false);
  const channels = ref([]);
  const countries = channelStore.getCountries.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

  // --

  function onCountrySelect(country) {
    channels.value = channelStore.getChannels(country.value);
  }

  // --

  onMounted(async () => {
    country.value = useGuessCountry();

    if (country.value !== '-') {
      onCountrySelect(country);
    }

    hasCountry.value = true;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide>
    <BigTitle>
      <slot />
    </BigTitle>
    <Foreground class="container">
      <GridItem :from="2" :to="11" class="wrapper">
        <ShowAnimation>
          <Card>
            <div class="logo-wrapper">
              <EventLogo :event="event" tiny />
            </div>
            <div class="columns">
              <div>
                <h3>Select country</h3>
                <Select v-if="hasCountry" :items="countries" :initial="country" @select="onCountrySelect" class="select" small />
              </div>
              <div class="channels">
                <h3>
                  Main card<span>Live at {{ mainTime }} {{ timezone }}</span>
                </h3>
                <ul>
                  <li v-for="channel in channels">
                    <Button v-if="channel.coming_soon" class="disabled"> Coming soon</Button>
                    <Button v-else :to="channel.link">
                      <img :src="channel.logo" :alt="channel.name" width="120" height="54" />
                    </Button>
                    <span>{{ channel.subtitle }}</span>
                  </li>
                </ul>
              </div>

              <div v-if="event.prelims_starts_at" class="prelims">
                <h3>
                  {{ event.slug === 'glory-99' ? 'Superfights' : 'Prelims' }}<span>Live at {{ prelimsTime }} {{ timezone }}</span>
                </h3>
                <ul>
                  <li v-if="event.youtube_prelims">
                    <Button :to="event.youtube_prelims">
                      <IconYouTube />
                    </Button>
                  </li>
                  <li v-if="event.facebook_prelims">
                    <Button :to="event.facebook_prelims">
                      <IconFacebook />
                    </Button>
                  </li>
                  <li>
                    <Button to="/watch#fast-channels">
                      <span>Fast<br />channels</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </Card>
        </ShowAnimation>
      </GridItem>
    </Foreground>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding-top: px(90);
    padding-bottom: px(128);
    margin-bottom: -$content-padding-bottom;

    background-color: $black;

    .container {
      @include grid;
    }

    .wrapper {
    }

    .card {
      padding: px(50) 0 0;
      background-color: $black-2;

      .logo-wrapper {
        display: flex;
        justify-content: center;

        padding-bottom: px(26);
      }

      .columns {
        display: flex;

        > div {
          display: flex;
          flex-direction: column;

          align-items: stretch;

          width: 100%;
          padding: px(38) 1.5vw px(42);

          @include break-min-small {
            border-right: px(1) solid $black-5;
          }

          @include break-small {
            border-bottom: px(1) solid $black-5;
          }

          &:last-child {
            border-right: none;
            border-bottom: none;
          }
        }
      }

      > div {
        &:last-child {
          border-top: px(1) solid $black-5;
        }
      }

      h3 {
        position: relative;

        font-size: px(32);
        text-align: center;

        margin-bottom: px(38);

        span {
          position: absolute;
          left: 50%;
          bottom: px(-22);

          transform: translateX(-50%);

          @include industry-demi(px(12));
        }
      }

      .channels {
        ul {
          li {
            span {
              display: block;
              text-align: center;
              @include industry-demi(px(10));

              padding: 0 px(12);

              margin-top: px(5);
            }
          }
        }
      }

      ul {
        display: flex;
        justify-content: stretch;
        gap: px(10);

        li {
          width: 100%;

          .disabled {
            pointer-events: none;
            opacity: 0.4;
          }

          a {
            width: 100%;
            white-space: normal;

            text-align: center;
            font-size: px(12);
            line-height: 1;

            padding-left: 0;
            padding-right: 0;

            svg {
              width: px(22);
              height: px(22);
            }

            img {
              width: px(120);
              height: px(54);
            }
          }
        }
      }
    }

    @include break-large {
      .wrapper {
        @include column(1, 12);
      }
      .card {
        .columns {
        }

        ul {
          gap: px(6);
        }
      }
    }

    @include break-medium {
      .card {
        ul {
          gap: px(4);
        }
      }
    }

    @include break-small {
      .card {
        .columns {
          flex-direction: column;

          > div {
            padding-left: px(32);
            padding-right: px(32);

            &:first-child {
              h3 {
                margin-bottom: px(15);
              }
            }
          }
        }

        ul {
          gap: px(8);
        }
      }
    }

    @include break-phone {
      .card {
        padding-bottom: px(8);

        .logo-wrapper {
          padding-bottom: px(24);
        }

        .columns {
          > div {
            padding: px(28) px(28) px(32);
          }
        }
      }
    }
  }
</style>
