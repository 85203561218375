<script setup>
  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <HomeHero :events="page.events" />
  <NewsHome :highlights="page.highlights" :hide_title="page.highlights_hide_title" :solid="page.highlights_solid" :darken="page.highlights_darken">{{ page.highlights_title }}</NewsHome>
  <NewsletterPromoBlock home :data="page" />
  <PastEvents :events="page.past_events">{{ page.past_events_title }}</PastEvents>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
